import { FunctionComponent, useEffect } from "react";
import {
  ConfigurationFormSingleSelectionItem,
  ConfigurationFormSingleSelectionWithImgItem,
  ConfiguratorFormDescriptionTab,
  ConfiguratorFormMultipleSelection,
  ConfiguratorFormMultipleSelectionCheckbox,
  ConfiguratorFormSingleSelectionGroup,
  ConfiguratorFormSingleSelectionTab,
  ConfiguratorFormSingleSelectionWithImgGroup,
  ConfiguratorFormSingleSelectionWithImgTab,
  ConfiguratorFormMultipleSelectionCheckboxItem,
  ConfiguratorFormMultipleSelectionItem,
  ConfiguratorClientInfos,
  ConfiguratorDistributorOptions,
  DealerListType
} from "_api";
import {
  AdditionalOptions,
  Checkbox,
  ClientInformations,
  CommentaryFormOption,
  DiscountFormOption,
  HorizontalFinitionOptionValue,
  HorizontalOptionValue,
  StartUpCostsOption,
  //TakeOverOption,
  VerticalValueSwitch
} from "_components";
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Control } from "react-hook-form";
import { FormValues } from "_types";
import { useTranslation } from "react-i18next";
import './configurator-form-section.scss';

type ConfiguratorFormSectionProps = {
  sectionName: string,
  section: ConfiguratorFormSingleSelectionTab | ConfiguratorFormSingleSelectionWithImgTab | ConfiguratorFormDescriptionTab | ConfiguratorFormMultipleSelection | ConfiguratorFormMultipleSelectionCheckbox | ConfiguratorClientInfos | ConfiguratorDistributorOptions,
  control: Control<FormValues>,
  dealerList?: DealerListType[],
  availability : string
  scom : string | "";
}

export const ConfiguratorFormSection: FunctionComponent<ConfiguratorFormSectionProps> = ({availability, sectionName, section, control, dealerList ,scom}) => {
  const { t } = useTranslation();

  const scomValue = scom === "" ? 0 : parseInt(scom);
  
  const isDisabled =
  (sectionName === "carrier"  &&   scomValue > 30)|| 
  (sectionName === "finish" &&    scomValue >= 30) ||
  (sectionName === "pack" && scomValue > 30) ||
  (sectionName === "cellOption"  &&  scomValue > 30) ||
  (sectionName === "carrierOption"   && scomValue >= 30);

  const scrollClass = isDisabled ? 'configurator-form-section__content disabled' : 'configurator-form-section__content';

  const renderSection = () => {
    if (section && section.type === 'singleSelection') {
      return (
        <>
          {(section as ConfiguratorFormSingleSelectionTab).groups.map((group: ConfiguratorFormSingleSelectionGroup) => {
            const options = group.items.map((item: ConfigurationFormSingleSelectionItem) => {
              return ({
                _id: item.id,
                id: item.value,
                title : item.title,
                label: item.title,
                price: item.price,
                value: item.value,
                picture: item.img
              });
            })
            return (
              <HorizontalOptionValue key={group.name} label={group.title}  name={sectionName + '.' + group.name} options={options} control={control} />
            );
          })
          }
        </>
      );
    }
    else if (section && section.type === 'singleSelectionWithImg') {
      return (
        <>
          {(section as ConfiguratorFormSingleSelectionWithImgTab).groups.map((group: ConfiguratorFormSingleSelectionWithImgGroup) => {
            const options = group.items.map((item: ConfigurationFormSingleSelectionWithImgItem) => {
              return ({
                _id: item.id,
                id: item.value,
                label: item.title,
                price: item.price,
                value: item.value,
                picture: item.img
              });
            })
            return (
              <HorizontalFinitionOptionValue key={group.name} label={group.title} name={sectionName + '.' + group.name} options={options} control={control} />
            );
          })
          }
        </>
      );
    }
    else if (section && section.type === 'description') {
      return (
        <>
          {(section as ConfiguratorFormDescriptionTab).infos.map((info: { title: string, items: string[] }) => {
            return (
              <div className="configurator-accordion__form-description-wrapper" key={info.title}>
                <div className="configurator-accordion__form-description-title">{info.title}</div>
                <ul className="configurator-accordion__form-description-content">
                  {info.items.map((item: string) => {
                    return <li className="configurator-accordion__form-description-content-item" key={item}>{item}</li>
                  })}
                </ul>
              </div>
            );
          })}
        </>
      );
    }
    else if (section && section.type === 'multipleSelection') {
      return (
        <>
          {(section as ConfiguratorFormMultipleSelection).items.map((item: ConfiguratorFormMultipleSelectionItem) => {
            return (
              <VerticalValueSwitch key={item.value} name={sectionName + '.' + item.value} title={item.title} price={item.price} description={item.description} disabled={item.disabled} control={control} />
            );
          })}
        </>
      );
    }
    else if (section && section.type === 'multipleSelectionCheckbox') {
      return (
        <>
          {(section as ConfiguratorFormMultipleSelectionCheckbox).items.map((item: ConfiguratorFormMultipleSelectionCheckboxItem) => {
            return (
              <Checkbox key={item.value} id={item.value} name={sectionName + '.' + item.value} title={item.title} price={item.price} weight={item.weight} control={control} />
            );
          })}
        </>
      );
    }
    else if (section && section.type === 'clientInfos') {
      return (
        <ClientInformations name={sectionName} control={control} dealerList={dealerList} />
      );
    }
    else if (sectionName === 'distributorOptions') {
      return (
        <div className="configurator-form-section">

          <div className="configurator-form-section__group">
            <div className="configurator-form-section__group-label">{t('additional_options')}</div>
            <AdditionalOptions name={sectionName + '.additionalOptions'} control={control} />
          </div>

          {/*<div className="configurator-form-section__group">
            <TakeOverOption name={sectionName + '.takeOver'} control={control} />
          </div>*/}
          <div className="configurator-form-section__group">
            <StartUpCostsOption name={sectionName + '.startUpCosts'} control={control} />
          </div>
          <div className="configurator-form-section__group">
            <CommentaryFormOption name={sectionName + '.commentary'} control={control} />
          </div>
          <div className="configurator-form-section__group">
            <DiscountFormOption name={sectionName + '.discount'} control={control} />
          </div>
        </div>
      );
    }
    else {
      return '';
    }
  }

  return (
    <PerfectScrollbar  className={"configurator-accordion__form-section-scroll-content"} >
      <div className={scrollClass}>
      {renderSection()}
      </div>
    </PerfectScrollbar>
  )
}

export default ConfiguratorFormSection;